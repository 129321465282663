html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
}
body {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
  margin: 0;
  padding: 0;
  font-family:"Source Sans Pro",-apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,0.65);
  font-size: 1.6rem;
  user-select: text;
}


.overflow-h{
  overflow: hidden;
}

.overflow-y{
  overflow: hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: inherit;
  flex-direction: inherit;
  flex: 1 1 auto;
  transition: all 200ms ease-in;
}
:root{
  --transition: all ease-in 250ms;
  --app-background: #fff;
  --app-background-hue-2: #fff;
  --app-background-hue-3: #f2f2f2;
  --text-hue-1: #222;
  --text-hue-3: #666; 
  --normal-text: #323232;
  --accent-color: #546FFF;
  --accent-color-hue-2: rgb(20, 41, 155); 
  --logo-url: url('/static/logo_b.svg');
  --web-icon-url:url('/static/web_light.svg');
  --twitter-icon-url: url('/static/twitter_light.svg');
  --linkedin-icon-url:url('/static/linkedin_light.svg');
  --medium-icon-url: url('/static/medium_light.svg');
}


.App[theme="dark"] {
  --app-background: #111;
  --app-background-hue-2: #222;
  --app-background-hue-3: #383838;
  --text-hue-1: #FFFFFF;
  --text-hue-2: #909090;
  --text-hue-3: #909090;
  --normal-text: #FFFFFF;
  --accent-color: #FF3F2B;
  --accent-color-hue-2: rgb(173, 35, 20);
  --logo-url: url('/static/logo_w.svg');
  --web-icon-url:url('/static/web_dark.svg');
  --twitter-icon-url: url('/static/twitter_dark.svg');
  --linkedin-icon-url:url('/static/linkedin_dark.svg');
  --medium-icon-url: url('/static/medium_dark.svg');
}

.App{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: var(--app-background);
  color: var(--normal-text);
  width: 100%;
  max-width: 100%;
  position: relative;
}

.icon-button{
  background: transparent;
  border:none;
  cursor:pointer;
  color:var(--text-hue-1);
  border-radius: 50%;
  outline: none;
  padding: 0;
}
.icon{
  height: 30px;
  width: 30px;
}
.web-icon{
  background: var(--web-icon-url);
}
.twitter-icon{
  background: var(--twitter-icon-url);
}
.medium-icon{
  background: var(--medium-icon-url);
}
.linkedin-icon{
  background: var(--linkedin-icon-url);
}