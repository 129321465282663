html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
}
body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: inherit;
  width: inherit;
  margin: 0;
  padding: 0;
  font-family:"Source Sans Pro",-apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,0.65);
  font-size: 1.6rem;
  -webkit-user-select: text;
          user-select: text;
}


.overflow-h{
  overflow: hidden;
}

.overflow-y{
  overflow: hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: inherit;
  -webkit-flex-direction: inherit;
          flex-direction: inherit;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  transition: all 200ms ease-in;
}
:root{
  --transition: all ease-in 250ms;
  --app-background: #fff;
  --app-background-hue-2: #fff;
  --app-background-hue-3: #f2f2f2;
  --text-hue-1: #222;
  --text-hue-3: #666; 
  --normal-text: #323232;
  --accent-color: #546FFF;
  --accent-color-hue-2: rgb(20, 41, 155); 
  --logo-url: url('/static/logo_b.svg');
  --web-icon-url:url('/static/web_light.svg');
  --twitter-icon-url: url('/static/twitter_light.svg');
  --linkedin-icon-url:url('/static/linkedin_light.svg');
  --medium-icon-url: url('/static/medium_light.svg');
}


.App[theme="dark"] {
  --app-background: #111;
  --app-background-hue-2: #222;
  --app-background-hue-3: #383838;
  --text-hue-1: #FFFFFF;
  --text-hue-2: #909090;
  --text-hue-3: #909090;
  --normal-text: #FFFFFF;
  --accent-color: #FF3F2B;
  --accent-color-hue-2: rgb(173, 35, 20);
  --logo-url: url('/static/logo_w.svg');
  --web-icon-url:url('/static/web_dark.svg');
  --twitter-icon-url: url('/static/twitter_dark.svg');
  --linkedin-icon-url:url('/static/linkedin_dark.svg');
  --medium-icon-url: url('/static/medium_dark.svg');
}

.App{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  background: #fff;
  background: var(--app-background);
  color: #323232;
  color: var(--normal-text);
  width: 100%;
  max-width: 100%;
  position: relative;
}

.icon-button{
  background: transparent;
  border:none;
  cursor:pointer;
  color:#222;
  color:var(--text-hue-1);
  border-radius: 50%;
  outline: none;
  padding: 0;
}
.icon{
  height: 30px;
  width: 30px;
}
.web-icon{
  background: url('/static/web_light.svg');
  background: var(--web-icon-url);
}
.twitter-icon{
  background: url('/static/twitter_light.svg');
  background: var(--twitter-icon-url);
}
.medium-icon{
  background: url('/static/medium_light.svg');
  background: var(--medium-icon-url);
}
.linkedin-icon{
  background: url('/static/linkedin_light.svg');
  background: var(--linkedin-icon-url);
}


  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    /* Styles */

    .app_header .header_left .logo{
      padding-left: 2rem;
    }
    

    .app_content{
      padding-top: 4rem;
    }

    .app_header .main_nav_panel a{
      min-width: 40px;
    }

    .app_grid, .about_container{
      grid-template-columns: repeat(1, minmax(315px, 315px));
      padding: 0 2rem;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
  
    }

    .App .about_container .member_image {
      width: 120px;
      padding-top: 75%;
    
    }


    .button_filter{
      min-width: 80px;
    }
    .button_filter div span {
      display: none;
    }
  
    .app_card{
      min-width: 315px;
      width: 315px;
    }
  
    .about_content_wrapper{
      max-width: 310px;
    }
    .about_container .iwd_team_wrapper{
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: 16px;
    }
    .about_container .iwd_team_wrapper .team_member{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      margin: 0;
      width: auto;
      height: 120px;
      margin-bottom: 20px;
    }
    .about_container .iwd_team_wrapper .team_member .card_image_placeholder{
      padding-top: 0;
      width: 120px;
      margin-right:10px;
    }

    .about_container .dog_tag .member_name{
      margin:0;
    }
  
  }
  
  
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 360px) and (max-device-height: 667px) and (orientation : landscape){
    /* Styles */
    .app_header .header_left .logo{
      padding-left: 7rem;
    }

    .App .app_grid{
      padding: 0 7rem;
    }
    .App .about_container {
      padding: 0 10rem;
      -webkit-align-items: inherit;
              align-items: inherit;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
    }

    .iwd_team_wrapper{
      overflow: hidden;
      overflow-x: auto;
    }

   
  }
  
  @media only screen and (min-device-width: 360px) and (max-device-width: 599px) and (orientation : portrait){
    /* Styles */
    .app_content{
      padding-top: 4rem;
    }

    .app_header .header_left .logo{
      padding-left: 2rem;
    }

    .App .app_grid, .App .about_container{
      grid-template-columns: repeat(1, minmax(315px, 315px));
      padding: 0 2rem;
    }
    .App .about_container{
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
    }

    .App .app_card{
      width: 315px;
      max-width: 315px;
    }
    
  
    .about_container .about_content_wrapper{
      max-width: 315px;
    }
    .about_container .iwd_team_wrapper{
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: 16px;
    }
    .about_container .iwd_team_wrapper .team_member{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      margin: 0;
      width: auto;
      height: 120px;
      margin-bottom: 20px;
    }
    .about_container .iwd_team_wrapper .team_member .card_image_placeholder{
      padding-top: 0;
      width: 120px;
      margin-right:10px;
    }
    .about_container .dog_tag .member_name{
      margin:0;
    }

    .App .about_container .member_image {
      width: 120px;
      padding-top: 75%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    .App .about_container .team_member{
      width: 120px;
    }
    .App .about_container .member_image {
      width: 100%;
    }
    .app_content{
      padding-top: 4rem;
    }
  }

  /* Smartphones (portrait) ----------- */
@media only screen and (max-width : 599px) {
  /* Styles */
  .header_right .button_filter{
    min-width: 80px;
  }
  .header_right .button_filter div span {
    display: none;
  }

  .twitter_link{
    display: none;
  }
  .app_grid .twitter_link_mob{
    display: block;
    width: 3.6rem;
    border-radius: 50%;
    background: rgba(0,0,0,0.25);
}


}

  
.app_content{
    padding-top: 11.7rem;
    will-change: transform;
}


@-webkit-keyframes animate_loader{
    from {
        left: 0%;
        width:0%;
    }
    to {
        left: 100%;
        width:70%;
    }
}


@keyframes animate_loader{
    from {
        left: 0%;
        width:0%;
    }
    to {
        left: 100%;
        width:70%;
    }
}

.waiter{
    height:3px;
    background: var(--accent-color);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    transition: var(--transition);
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 99;
    
}
.waiter_loader{
    width: 30%;
    background: rgba(255,255,255,0.5);
    height:inherit;
    -webkit-animation: linear forwards 3s infinite animate_loader;
            animation: linear forwards 3s infinite animate_loader;
    position: relative;
    z-index: 99;
}
.waiter[loading="true"]{
    display: block;
}


.app_wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    background:var(--app-background);
    will-change: transform;
    
    
}
.app_wrapper > div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1 auto;
            flex:1 1 auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
}

.logo h3{
    font-family: 'Poppins', 'Helvetica-Neue',sans-serif;
    font-size: 6.4rem;
    font-weight: 600;
    letter-spacing: -1px;
    margin:0;
}
.highlight{
    color: var(--accent-color);
}


.logo{
    grid-column-start: 1;
    grid-column-end: -1;
}



.title_2{
    font-size: 4.8rem;
    margin: 5.0rem 0 2.0rem;
}
.title_3{
    font-size: 2.4rem;
    margin: 5.0rem 0 2.0rem;
}
.body_text{
    font-size: 1.8rem;
}

.button_primary:hover{
    background: var(--accent-color-hue-2);
}
.app_header{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    min-height: 80px;
    height: 80px;
    overflow: hidden;
    transition: var(--transition);
    left: 0;
    right: 0;
    position: relative;
    background:var(--app-background);
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
}

.app_header[floating="true"]{
    position: fixed;
    z-index: 9;
    
}
.app_header[visible="false"]{
    -webkit-transform: translate3d(0,-100%,0);
            transform: translate3d(0,-100%,0);
}

.app_header[floating="false"]{
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
}



.header_left{
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(2, minmax(100px, 100px));
    -webkit-align-items: baseline;
            align-items: baseline;
}
.header_right{
    min-width: 30%;
    -webkit-flex-basis: 30%;
            flex-basis: 30%;
    display: inherit;
    -webkit-flex-direction: inherit;
            flex-direction: inherit;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.button_primary{
    background: var(--accent-color);
    outline: none;
    color: #fff;
    padding: 10px;
    border: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    transition: var(--transition);
    box-sizing: border-box;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}
.button_filter{
    min-width: 200px;
    max-width: 200px;
}
.button_filter div{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.button_filter div span{
    padding: 0 10px;
}

.main_nav_panel{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    margin:0 25px;
}

.main_nav_panel a{
    margin:0 10px;
    min-width: 80px;
    color: inherit;
    text-decoration: none;
    text-align: center;
    font-size: 1.6rem;
}

.app_header .logo{
    grid-column-start: 1;
    padding-left:7rem;
}

.app_header .logo-icon{
    background: var(--logo-url);
    width:50px;
    height:31px;
    background-repeat: no-repeat;
}

.app_header .dot{
    height: 6px;
    width: 6px;
    color: #fff;
    background: #fff;
    border-radius: 100%;
    padding: 0;
    display: inline-block;
    margin-top: 3px;
}

.twitter_link{
    background: rgba(0,0,0,0.2);
    width: 28px;
    border-radius: 50%;
}
.sidenav_overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.sidenav_container{
    position: absolute;
    right:0;
    top: 0;
    bottom: 0;
    width: 360px;
    background: var(--app-background-hue-2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: var(--transition);
    z-index: 11;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.overlay_hidden{
    display: none;
}

.hidden{
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

.sidenav_header{
    height: 80px;
    min-height: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.sidenav_header .options_container{
    -webkit-flex: 80 1;
            flex: 80 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    padding-left: 50px;
}

.button_back{
    width: 80px;
}

.sidenav_content{
    padding: 20px; 
    padding-left: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.sidenav_content h3{
    font-family: 'Poppins', Helvetica-Neue, Helvetica, sans-serif;
    font-size: 2.4rem;
}

.sidenav_content .checkbox{
    margin: 6% 0;
}
.checkbox{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}
.checkbox span{
    padding-left: 10px;
}
.app_grid{
    display:grid;
    grid-gap: 30px;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 280px));
    /* temporarily here until alphabetical sort stays */
    /* padding-right: 20px; */
    padding: 0 7rem;
}
.twitter_link_mob{
    display: none;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.app_card{
    min-height: 530px;
    max-height: 530px;
    width:280px;
    min-width: 280px;
    box-shadow: 0 4px 10px 2px rgba(0,0,0,0.15);
    transition: var(--transition);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 1.0rem;
    box-sizing: border-box;
    border-radius: 6px;
    will-change: transform;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    background: var(--app-background-hue-2);
    -webkit-animation: 500ms ease-out forwards fadeIn;
            animation: 500ms ease-out forwards fadeIn;
}

.app_card:hover{
    box-shadow: 0 2px 50px 0 rgba(0,0,0,0.25);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}


.card_inner_content{
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.card_image_placeholder{
    background: var(--app-background-hue-3);
    width: 100%;
    padding-top: 100%;
    border-radius: 4px;
    background-size: cover;
    transition: var(--transition);
    
}

.card_head {
    margin-top: 10px;
    margin-bottom: 5px;
}
.card_inner_content .card_user_info .card_head h3{
    color: var(--text-hue-1);
    margin:0;
    font-size: 2.4rem;
    line-height: 31px;
}
.card_inner_content .card_user_info .card_head span{
    color: var(--text-hue-3);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
}

.card_inner_content .card_user_desc{
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--text-hue-2);
    
}

.card_user_info .card_tags span{
    background: var(--app-background-hue-3);
    padding: 10px;
    border-radius: 2em;
    display: inline-block;
    margin: 5px 5px 5px 0;
    line-height: 100%;
    font-size: 12px;
    color: var(--text-hue-2);
    box-sizing: border-box;
    height:32px;
}
.card_tags{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.card_tags .material-icons{
    font-size: 14px;
    height:14px;
    width:14px;
}
.icon-new {
    background-image: url('/static/icon-new.svg');
    background-repeat: no-repeat;
    width: 54px;
    height: 30px;
    position: absolute;
    z-index: 99;
    right:-6px;
    top:20px;
}
.user_social {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-grow: 20;
            flex-grow: 20;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    min-height: 34px;
    max-height: 34px;
    padding-top:10px;
}

.user_social a{
    margin-right: 10px;
}
.user_social span{
    display: inline-block;
    opacity: 0.65;
    cursor: pointer;
    transition: var(--transition);
    border-radius:50%;
}
.user_social span:hover{
    opacity: 1;
}



.about_container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 5rem;

}

.about_container a{
    color: var(--accent-color);
    text-decoration: none;
}



.content_wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 720px;
}

.content_wrapper .section {
    margin:0;
    margin-bottom: 3.0rem;
}

.content_wrapper h1,.content_wrapper h2,.content_wrapper h3{
    font-family: "Poppins", "Source Sans Pro",sans-serif; 
    box-sizing: border-box;
}


.content_wrapper .video_placeholder{
    padding-top: 56.25%;
    width:100%;
    background: rgba(48,48,48,1);
    border-radius: 4px;
    box-shadow: 0 4px 10px 2px rgba(0,0,0,0.15);
    max-height: 405px;
    margin-top:3.0rem;
    display: none;
}

.content_wrapper p{
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin:0;
}

.iwd_team_wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.iwd_team_wrapper .team_member{
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.team_member {
    width: 22rem;
    margin-right: 3.0rem;
    
}
.team_member:last-child{
    margin:0;
}

.dog_tag{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    
}

.team_member{
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    transition: var(--transition);
    background: var(--app-background-hue-2);
    padding: 1.0rem;
    border-radius: 0.6rem;
}

.team_member:hover{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

.dog_tag .member_name{
    font-size: 2.0rem;
    margin: 15px 0;
    margin-bottom: 10px;
    line-height: 1;
}

.dog_tag .user_social{
    -webkit-flex-basis: auto;
            flex-basis: auto;
    -webkit-flex-grow: inherit;
            flex-grow: inherit;
}

.about_container .member_image {
    width: 100%;
}
.team_member .card_image_placeholder {
    background-size: 100%;
}

.ivy{
    background: url("/static/ivy.jpg");
}
.zee{
    background: url("/static/zee.jpg");
}
.shashank{
    background: url("/static/shashank.jpg");
}
.nominate-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 5rem;
}
.nominate_button{
    text-align: center;
    width: 200px;
    display: inline-block;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-decoration: none;
    color: #fff;
    background: var(--accent-color);
    border-radius: 6px;
    font-family: "Poppins";
    font-size: 1.8rem;
    margin-top: 50px;
}
