.nominate-container{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5rem;
}
.nominate_button{
    text-align: center;
    width: 200px;
    display: inline-block;
    height: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: #fff;
    background: var(--accent-color);
    border-radius: 6px;
    font-family: "Poppins";
    font-size: 1.8rem;
    margin-top: 50px;
}