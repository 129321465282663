

  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    /* Styles */

    .app_header .header_left .logo{
      padding-left: 2rem;
    }
    

    .app_content{
      padding-top: 4rem;
    }

    .app_header .main_nav_panel a{
      min-width: 40px;
    }

    .app_grid, .about_container{
      grid-template-columns: repeat(1, minmax(315px, 315px));
      padding: 0 2rem;
      justify-content: flex-start;
  
    }

    .App .about_container .member_image {
      width: 120px;
      padding-top: 75%;
    
    }


    .button_filter{
      min-width: 80px;
    }
    .button_filter div span {
      display: none;
    }
  
    .app_card{
      min-width: 315px;
      width: 315px;
    }
  
    .about_content_wrapper{
      max-width: 310px;
    }
    .about_container .iwd_team_wrapper{
      flex-direction: column;
      margin-top: 16px;
    }
    .about_container .iwd_team_wrapper .team_member{
      display: flex;
      flex-direction: row;
      margin: 0;
      width: auto;
      height: 120px;
      margin-bottom: 20px;
    }
    .about_container .iwd_team_wrapper .team_member .card_image_placeholder{
      padding-top: 0;
      width: 120px;
      margin-right:10px;
    }

    .about_container .dog_tag .member_name{
      margin:0;
    }
  
  }
  
  
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 360px) and (max-device-height: 667px) and (orientation : landscape){
    /* Styles */
    .app_header .header_left .logo{
      padding-left: 7rem;
    }

    .App .app_grid{
      padding: 0 7rem;
    }
    .App .about_container {
      padding: 0 10rem;
      align-items: inherit;
      justify-content: flex-start;
    }

    .iwd_team_wrapper{
      overflow: hidden;
      overflow-x: auto;
    }

   
  }
  
  @media only screen and (min-device-width: 360px) and (max-device-width: 599px) and (orientation : portrait){
    /* Styles */
    .app_content{
      padding-top: 4rem;
    }

    .app_header .header_left .logo{
      padding-left: 2rem;
    }

    .App .app_grid, .App .about_container{
      grid-template-columns: repeat(1, minmax(315px, 315px));
      padding: 0 2rem;
    }
    .App .about_container{
      justify-content: flex-start;
    }

    .App .app_card{
      width: 315px;
      max-width: 315px;
    }
    
  
    .about_container .about_content_wrapper{
      max-width: 315px;
    }
    .about_container .iwd_team_wrapper{
      flex-direction: column;
      margin-top: 16px;
    }
    .about_container .iwd_team_wrapper .team_member{
      display: flex;
      flex-direction: row;
      margin: 0;
      width: auto;
      height: 120px;
      margin-bottom: 20px;
    }
    .about_container .iwd_team_wrapper .team_member .card_image_placeholder{
      padding-top: 0;
      width: 120px;
      margin-right:10px;
    }
    .about_container .dog_tag .member_name{
      margin:0;
    }

    .App .about_container .member_image {
      width: 120px;
      padding-top: 75%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    .App .about_container .team_member{
      width: 120px;
    }
    .App .about_container .member_image {
      width: 100%;
    }
    .app_content{
      padding-top: 4rem;
    }
  }

  /* Smartphones (portrait) ----------- */
@media only screen and (max-width : 599px) {
  /* Styles */
  .header_right .button_filter{
    min-width: 80px;
  }
  .header_right .button_filter div span {
    display: none;
  }

  .twitter_link{
    display: none;
  }
  .app_grid .twitter_link_mob{
    display: block;
    width: 3.6rem;
    border-radius: 50%;
    background: rgba(0,0,0,0.25);
}


}

  