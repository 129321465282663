.app_content{
    padding-top: 11.7rem;
    will-change: transform;
}


@keyframes animate_loader{
    from {
        left: 0%;
        width:0%;
    }
    to {
        left: 100%;
        width:70%;
    }
}

.waiter{
    height:3px;
    background: var(--accent-color);
    display: flex;
    flex-direction: column;
    transition: var(--transition);
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 99;
    
}
.waiter_loader{
    width: 30%;
    background: rgba(255,255,255,0.5);
    height:inherit;
    animation: linear forwards 3s infinite animate_loader;
    position: relative;
    z-index: 99;
}
.waiter[loading="true"]{
    display: block;
}


.app_wrapper{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    background:var(--app-background);
    will-change: transform;
    
    
}
.app_wrapper > div{
    display: flex;
    flex:1 1 auto;
    flex-direction: column;
    overflow: hidden;
}

.logo h3{
    font-family: 'Poppins', 'Helvetica-Neue',sans-serif;
    font-size: 6.4rem;
    font-weight: 600;
    letter-spacing: -1px;
    margin:0;
}
.highlight{
    color: var(--accent-color);
}


.logo{
    grid-column-start: 1;
    grid-column-end: -1;
}



.title_2{
    font-size: 4.8rem;
    margin: 5.0rem 0 2.0rem;
}
.title_3{
    font-size: 2.4rem;
    margin: 5.0rem 0 2.0rem;
}
.body_text{
    font-size: 1.8rem;
}

.button_primary:hover{
    background: var(--accent-color-hue-2);
}