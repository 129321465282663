.app_header{
    display: flex;
    flex-direction:row;
    min-height: 80px;
    height: 80px;
    overflow: hidden;
    transition: var(--transition);
    left: 0;
    right: 0;
    position: relative;
    background:var(--app-background);
    transform: translate3d(0,0,0);
}

.app_header[floating="true"]{
    position: fixed;
    z-index: 9;
    
}
.app_header[visible="false"]{
    transform: translate3d(0,-100%,0);
}

.app_header[floating="false"]{
    transform: translate3d(0,0,0);
}



.header_left{
    flex: 1 1 auto;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(2, minmax(100px, 100px));
    align-items: baseline;
}
.header_right{
    min-width: 30%;
    flex-basis: 30%;
    display: inherit;
    flex-direction: inherit;
    justify-content: flex-end;
}

.button_primary{
    background: var(--accent-color);
    outline: none;
    color: #fff;
    padding: 10px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
    box-sizing: border-box;
    flex: 1 1 auto;
}
.button_filter{
    min-width: 200px;
    max-width: 200px;
}
.button_filter div{
    display: flex;
    align-items: center;
}
.button_filter div span{
    padding: 0 10px;
}

.main_nav_panel{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:0 25px;
}

.main_nav_panel a{
    margin:0 10px;
    min-width: 80px;
    color: inherit;
    text-decoration: none;
    text-align: center;
    font-size: 1.6rem;
}

.app_header .logo{
    grid-column-start: 1;
    padding-left:7rem;
}

.app_header .logo-icon{
    background: var(--logo-url);
    width:50px;
    height:31px;
    background-repeat: no-repeat;
}

.app_header .dot{
    height: 6px;
    width: 6px;
    color: #fff;
    background: #fff;
    border-radius: 100%;
    padding: 0;
    display: inline-block;
    margin-top: 3px;
}

.twitter_link{
    background: rgba(0,0,0,0.2);
    width: 28px;
    border-radius: 50%;
}