.about_container{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5rem;

}

.about_container a{
    color: var(--accent-color);
    text-decoration: none;
}



.content_wrapper{
    display: flex;
    flex-direction: column;
    max-width: 720px;
}

.content_wrapper .section {
    margin:0;
    margin-bottom: 3.0rem;
}

.content_wrapper h1,.content_wrapper h2,.content_wrapper h3{
    font-family: "Poppins", "Source Sans Pro",sans-serif; 
    box-sizing: border-box;
}


.content_wrapper .video_placeholder{
    padding-top: 56.25%;
    width:100%;
    background: rgba(48,48,48,1);
    border-radius: 4px;
    box-shadow: 0 4px 10px 2px rgba(0,0,0,0.15);
    max-height: 405px;
    margin-top:3.0rem;
    display: none;
}

.content_wrapper p{
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin:0;
}

.iwd_team_wrapper{
    display: flex;
    flex-direction: row;
}
.iwd_team_wrapper .team_member{
    flex: 1 1 auto;
}

.team_member {
    width: 22rem;
    margin-right: 3.0rem;
    
}
.team_member:last-child{
    margin:0;
}

.dog_tag{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.team_member{
    filter: grayscale(1);
    transition: var(--transition);
    background: var(--app-background-hue-2);
    padding: 1.0rem;
    border-radius: 0.6rem;
}

.team_member:hover{
    filter: grayscale(0);
}

.dog_tag .member_name{
    font-size: 2.0rem;
    margin: 15px 0;
    margin-bottom: 10px;
    line-height: 1;
}

.dog_tag .user_social{
    flex-basis: auto;
    flex-grow: inherit;
}

.about_container .member_image {
    width: 100%;
}
.team_member .card_image_placeholder {
    background-size: 100%;
}

.ivy{
    background: url("/static/ivy.jpg");
}
.zee{
    background: url("/static/zee.jpg");
}
.shashank{
    background: url("/static/shashank.jpg");
}