.sidenav_overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.sidenav_container{
    position: absolute;
    right:0;
    top: 0;
    bottom: 0;
    width: 360px;
    background: var(--app-background-hue-2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: var(--transition);
    z-index: 11;
    display: flex;
    flex-direction: column;
}

.overlay_hidden{
    display: none;
}

.hidden{
    transform: translateX(100%);
}

.sidenav_header{
    height: 80px;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sidenav_header .options_container{
    flex: 80;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 50px;
}

.button_back{
    width: 80px;
}

.sidenav_content{
    padding: 20px; 
    padding-left: 50px;
    display: flex;
    flex-direction: column;
}
.sidenav_content h3{
    font-family: 'Poppins', Helvetica-Neue, Helvetica, sans-serif;
    font-size: 2.4rem;
}

.sidenav_content .checkbox{
    margin: 6% 0;
}