@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
.app_card{
    min-height: 530px;
    max-height: 530px;
    width:280px;
    min-width: 280px;
    box-shadow: 0 4px 10px 2px rgba(0,0,0,0.15);
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    padding: 1.0rem;
    box-sizing: border-box;
    border-radius: 6px;
    will-change: transform;
    filter: grayscale(1);
    background: var(--app-background-hue-2);
    animation: 500ms ease-out forwards fadeIn;
}

.app_card:hover{
    box-shadow: 0 2px 50px 0 rgba(0,0,0,0.25);
    filter: grayscale(0);
}


.card_inner_content{
    font-family: 'Source Sans Pro', sans-serif;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.card_image_placeholder{
    background: var(--app-background-hue-3);
    width: 100%;
    padding-top: 100%;
    border-radius: 4px;
    background-size: cover;
    transition: var(--transition);
    
}

.card_head {
    margin-top: 10px;
    margin-bottom: 5px;
}
.card_inner_content .card_user_info .card_head h3{
    color: var(--text-hue-1);
    margin:0;
    font-size: 2.4rem;
    line-height: 31px;
}
.card_inner_content .card_user_info .card_head span{
    color: var(--text-hue-3);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
}

.card_inner_content .card_user_desc{
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--text-hue-2);
    
}

.card_user_info .card_tags span{
    background: var(--app-background-hue-3);
    padding: 10px;
    border-radius: 2em;
    display: inline-block;
    margin: 5px 5px 5px 0;
    line-height: 100%;
    font-size: 12px;
    color: var(--text-hue-2);
    box-sizing: border-box;
    height:32px;
}
.card_tags{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.card_tags .material-icons{
    font-size: 14px;
    height:14px;
    width:14px;
}
.icon-new {
    background-image: url('/static/icon-new.svg');
    background-repeat: no-repeat;
    width: 54px;
    height: 30px;
    position: absolute;
    z-index: 99;
    right:-6px;
    top:20px;
}
.user_social {
    flex-basis: 100%;
    flex-grow: 20;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    min-height: 34px;
    max-height: 34px;
    padding-top:10px;
}

.user_social a{
    margin-right: 10px;
}
.user_social span{
    display: inline-block;
    opacity: 0.65;
    cursor: pointer;
    transition: var(--transition);
    border-radius:50%;
}
.user_social span:hover{
    opacity: 1;
}


