.app_grid{
    display:grid;
    grid-gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 280px));
    /* temporarily here until alphabetical sort stays */
    /* padding-right: 20px; */
    padding: 0 7rem;
}
.twitter_link_mob{
    display: none;
}